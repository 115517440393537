import http from '@/utils/http';

/**
 * 会员签到日志
 */
export default {
  getList: {
    p: 'post,/member/signin/getlist',
    r: (data) => http({ url: '/member/signin/getlist', method: 'post', data })
  }
};
